
.create-succeed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    font-size: 23px;
    .hint {
        margin: 30px 0;
    }
    ::v-deep .blue-button {
        background-color: #1122d8;
        border-color: #1122d8;
        color: #fff;
        font-size: 14px;
    }
}
