
.path {
    margin-bottom: 40px;
    color: #999;
    .item {
        transition: all 0.3s;
        cursor: pointer;
    }
    .item:hover {
        color: #1122d8;
    }
    .current {
        color: #343441;
    }
}
